import React, { useState } from "react";
import { Modal, List, Avatar, Pagination, Button } from "antd";
import { getRef } from "../api/user";
import ButtonGroup from "antd/lib/button/button-group";

interface IUserView {
  userid: string;
  close: () => any;
}
export const RefView: React.FC<IUserView> = ({ userid, close }) => {
  const [loading, setLoading] = useState(true);
  const [currList, setCurrList] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  React.useEffect(() => {
    fetchInfo();
  }, [page]);

  async function fetchInfo() {
    setLoading(true);
    const res = await getRef(userid, page, 10);
    const data = res.data.data;
    const newList = [...currList, ...data];
    setCurrList(newList);
    setLoading(false);
  }

  return (
    <Modal title="User List" visible onCancel={close} closable>
      <List
        loading={loading}
        itemLayout="horizontal"
        dataSource={currList}
        pagination={false}
        renderItem={item => {
          return (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={item.avatar} />}
                title={item.username}
                description={item.name}
              />
            </List.Item>
          );
        }}
      />
      <ButtonGroup
        style={{
          width: "100%",
          textAlign: "center"
        }}
      >
        <Button
          loading={loading}
          onClick={() => {
            setPage(s => s + 1);
            setPageSize(s => s + 10);
          }}
        >
          Load More
        </Button>
      </ButtonGroup>
    </Modal>
  );
};
