import React, { useState } from "react";
import { getPlayerHistory, getSingleGameHistory } from "../api/quiz";
import { Modal, List, Avatar, Pagination, Button, notification } from "antd";
import { GameView } from "./GameView";

interface IQuizHistory {
  userid: string;
  close: () => any;
}
export const QuizHistory: React.FC<IQuizHistory> = ({ userid, close }) => {
  const pageSize = 10;
  const [loading, setLoading] = useState(true);
  const [openIt, setOpenIt] = useState(false);
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0);
  const [currList, setCurrList] = useState([]);
  const [page, setPage] = useState(0);

  React.useEffect(() => {
    fetchInfo();
  }, [page]);

  async function fetchInfo() {
    setLoading(true);
    const info = await getPlayerHistory(userid, page, pageSize);
    setTotal(info.data.data.total);
    setCurrList(info.data.data.docs);
    setLoading(false);
  }

  return (
    <Modal title="Quiz List" visible onCancel={close} closable>
      <List
        loading={loading}
        itemLayout="horizontal"
        dataSource={currList}
        pagination={false}
        renderItem={item => {
          return (
            <>
              <List.Item
                actions={[
                  <Button
                    disabled={item.status !== "finished"}
                    icon="eye"
                    type="primary"
                    onClick={async () => {
                      setLoading(true);
                      const a = await getSingleGameHistory(item.quizid);
                      if (!a.data.data) {
                        notification.error({
                          message: "No data found for the quiz!"
                        });
                        setLoading(false);
                      } else {
                        const justMe = a.data.data.userData.filter(
                          (a: any) => a.userid === userid
                        );

                        console.log(justMe);
                        const newObj = Object.assign({}, a.data.data, {
                          userData: justMe
                        });
                        setData(newObj);
                        setLoading(false);
                        setOpenIt(true);
                      }
                    }}
                  />
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar src={item.banner} />}
                  title={item.quizid}
                  description={item.status}
                />
              </List.Item>
            </>
          );
        }}
      />
      <Pagination
        total={total}
        pageSize={pageSize}
        current={page + 1}
        onChange={pg => {
          setPage(pg - 1);
        }}
      />
      {openIt && (
        <GameView
          close={() => {
            setOpenIt(false);
          }}
          data={data}
        />
      )}
    </Modal>
  );
};
