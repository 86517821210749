import React, { useState } from "react";
import {
  PageHeader,
  Form,
  Input,
  Tabs,
  Button,
  Switch,
  notification
} from "antd";
import { MainLayout } from "../../layouts/MainLayout";
import { getVersion, setVersion as _setVersion } from "../../api/tags";

const { TabPane } = Tabs;

export const Version = () => {
  const [maintenance, setMaintenance] = useState(false);
  const [version, setVersion] = useState("0.0.1");

  React.useEffect(() => {
    async function setIt() {
      const vr = await getVersion();
      console.log(vr);
      const { isMaintenance, version } = vr;
      setMaintenance(isMaintenance);
      setVersion(version);
    }

    setIt();
  }, []);

  const update = async (e: any) => {
    e.preventDefault();
    try {
      const res = await _setVersion(maintenance, version);
      if (res.done) {
        notification.success({
          message: "Done"
        });
      }
    } catch (err) {
      notification.error({
        message: "Error, try again"
      });
    }
  };

  return (
    <MainLayout>
      <PageHeader
        style={{
          paddingLeft: 0
        }}
        title="Version"
        subTitle="Update Version and Maintenance status"
      />
      <div className="card-container">
        <Tabs type="card">
          <TabPane tab="Change Version & Maintenance" key="1">
            <Form
              layout="vertical"
              style={{
                maxWidth: 300
              }}
              onSubmit={update}
            >
              <Form.Item label="Maintenance" required>
                <Switch
                  checked={maintenance}
                  onChange={e => {
                    setMaintenance(e);
                  }}
                />
              </Form.Item>

              <Form.Item label="Version" required>
                <Input
                  value={version}
                  onChange={e => {
                    setVersion(e.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Confirm
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </div>
    </MainLayout>
  );
};
