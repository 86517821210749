import React, { useRef, useState, useEffect } from "react";
import {
  PageHeader,
  Divider,
  Tag,
  Input,
  Button,
  notification,
  Modal
} from "antd";
import { MainLayout } from "../../layouts/MainLayout";
import {
  getCategories,
  setCategories as _setCategories,
  deleteUi,
  update
} from "../../api/tags";
import ButtonGroup from "antd/lib/button/button-group";

export const AddCategories = () => {
  const [categories, setCategories] = useState<any>([]);
  const ref = useRef(null);
  const [inp, setInp] = useState<string>("");

  useEffect(() => {
    tgs();
  }, []);

  async function tgs() {
    const _cats: any = await getCategories();

    console.log(_cats);
    if (_cats.status) {
      console.log(_cats);
      setCategories(_cats.data.categorys);
    }
  }

  const addCategories = async () => {
    if (inp !== "") {
      const done: any = await _setCategories(inp.toLowerCase());
      console.log(done);
      if (done.status) {
        tgs();
        notification.success({
          message: "Added new categories"
        });
        setInp("");
      } else {
        notification.error({
          message: "duplicate entry!!"
        });
      }
    }
  };
  return (
    <MainLayout>
      <PageHeader title="Add Categories" />

      <Input
        placeholder="Enter new categories"
        value={inp}
        onChange={e => setInp(e.target.value)}
      />
      <Button type="primary" onClick={addCategories}>
        Add Categories
      </Button>
      <Divider />
      {categories.map((tg: any, i: any) => (
        <div
          style={{
            margin: "10px",
            background: "whitesmoke",
            padding: "10px",
            width: "fit-content",
            display: "inline-block",
            borderRadius: "10px",
            border: "1px solid #c5c5c552"
          }}
        >
          <Tag
            style={{
              border: "none"
            }}
          >
            {tg.name}
          </Tag>
          <ButtonGroup>
            <Button
              size="small"
              onClick={async () => {
                await deleteUi(tg.name, "category");
                tgs();
              }}
              type="ghost"
              icon="delete"
            />
            <Button
              size="small"
              onClick={() => {
                Modal.confirm({
                  icon: null,
                  content: (
                    <div
                      style={{
                        margin: "10px"
                      }}
                    >
                      <Input ref={ref} defaultValue={tg.name} />
                    </div>
                  ),
                  onOk: async () => {
                    await update(tg.name, ref.current.state.value, "category");
                    tgs();
                  },
                  onCancel: () => {}
                });
              }}
              type="ghost"
              icon="edit"
            />
          </ButtonGroup>
        </div>
      ))}
    </MainLayout>
  );
};
