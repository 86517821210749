import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Skeleton, Avatar, Empty, Button } from "antd";
import chunk from "lodash.chunk";
import { getBanners, removeBanner } from "../api/quiz";

export interface IImageSelector {
  close: () => void;
  onSelect: (uri: string) => void;
}

export interface IImageView {
  uri: string;
  onRemove: (uri: string) => void;
  onSelect: (uri: string) => void;
}

export const ImageView: React.FC<IImageView> = ({
  uri,
  onRemove,
  onSelect
}) => {
  return (
    <div
      className="kn-btn-img"
      style={{
        padding: "5px",
        border: "1px solid whitesmoke",
        borderRadius: "15px"
      }}
    >
      <Button
        size="small"
        shape="circle-outline"
        icon="close"
        style={{
          margin: "5px 0"
        }}
        onClick={() => onRemove(uri)}
      />
      <div
        onClick={() => {
          onSelect(uri);
        }}
      >
        <Avatar src={uri} shape="square" size={120} />
      </div>
    </div>
  );
};

export const ImageSelector: React.FC<IImageSelector> = ({
  close,
  onSelect
}) => {
  const [loading, setLoading] = useState(false);
  const [bannerList, setBannerList] = useState<any[]>([]);

  useEffect(() => {
    async function fh() {
      setLoading(true);
      const banners = await getBanners();

      // Chunk array
      console.log(banners.data.data);
      const cArr = chunk(banners.data.data, 3);

      console.log(cArr);
      setBannerList(cArr);
      setLoading(false);
    }
    fh();
  }, []);

  function deleteImage(uri: string) {
    Modal.confirm({
      title: "Delete Image",
      content: <div> Are you sure you want to delete this image?</div>,
      onOk: async () => {
        setLoading(true);
        const id = uri.split("/")[uri.split("/").length - 1];
        console.log(id);
        await removeBanner(id);
        const newBannerList = bannerList.flat(2).filter(url => url !== uri);
        const cArr = chunk(newBannerList, 3);
        setBannerList(cArr);
        setLoading(false);
        console.log("Deleted");
      }
    });
  }

  return (
    <>
      <Modal
        footer={false}
        visible
        centered
        onCancel={close}
        bodyStyle={{
          height: 500,
          overflow: "auto"
        }}
      >
        {loading ? (
          <>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </>
        ) : (
          <>
            {bannerList.map((item, i) => {
              return (
                <Row gutter={[12, 12]} key={i} align="middle" justify="center">
                  {item.map((uri: string) => {
                    return (
                      <Col
                        span={8}
                        key={uri}
                        className="kw-image-selector"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          borderRadius: "4px"
                        }}
                      >
                        <ImageView
                          uri={uri}
                          onSelect={onSelect}
                          onRemove={deleteImage}
                        />
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
            {bannerList.length === 0 && <Empty />}
          </>
        )}
      </Modal>
    </>
  );
};
