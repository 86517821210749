import axios from "./axios";
import urls from "../config/url";
import { AuthService } from "../config/auth";
import { notification } from "antd";

export async function login(mobile: string) {
  const res = await axios({
    method: "post",
    url: `${urls.API_URL}/auth/login?mobile=${mobile}`,
    headers: {
      "Content-Type": "application/json"
    }
  });
  return res;
}

export async function verify(otp: string, mobile: string, password: string) {
  const res = await axios({
    method: "post",
    url: `${urls.API_URL}/auth/staff/verify?otp=${otp}&mobile=${mobile}&password=${password}`,
    headers: {
      "Content-Type": "application/json"
    }
  });

  return res;
}

export async function getAll(page: number, limit: number, search: any) {
  const res = await axios(
    `${urls.API_URL}/users/admin/all?limit=${limit}&page=${page}&search=${search}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    }
  );

  return res;
}

export async function updateRoles({ userid, status, role }: any) {
  const toSend = {
    userid,
    status,
    role
  };

  const res = await axios.post(`${urls.API_URL}/users/admin/role`, toSend, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });

  return res;
}

export async function getInfo() {
  const res = await axios(
    `${urls.API_URL}/users?info=avatar%2Cname%2Cusername`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    }
  );

  return res;
}

export async function setPass(old: string, newPass: string) {
  try {
    const res = await axios(`${urls.API_URL}/users/staff/change-password`, {
      method: "post",
      data: {
        newPass,
        old
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    });

    return res;
  } catch (error) {
    notification.error({
      message: "Unauthorized"
    });
  }
}

export async function genPass(userid: string) {
  try {
    const res = await axios(`${urls.API_URL}/users/staff/gen-pass`, {
      method: "post",
      data: {
        userid
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    });

    return res;
  } catch (error) {
    notification.error({
      message: "Unauthorized"
    });
    return {};
  }
}

export async function getInfoFromIds(userList: string[]) {
  const res = await axios(`${urls.API_URL}/users/usersinfo`, {
    method: "get",
    params: {
      userList
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });

  return res;
}

export async function getRef(userid: string, page: number, limit: number) {
  const res = await axios(`${urls.API_URL}/users/referent`, {
    method: "get",
    params: {
      userid,
      page,
      limit
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });

  return res;
}

export async function sendMessage(
  userid: string,
  title: string,
  message: string
) {
  const res = await axios(`${urls.API_URL}/users/send-push`, {
    method: "post",
    params: {
      userid,
      title,
      message
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });

  return res;
}
