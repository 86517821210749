import axios from "./axios";
import urls from "../config/url";
import { AuthService } from "../config/auth";
import { IPackage } from "../components/AddNewPackage";

export async function addPackage(formData: IPackage) {
  try {
    const res = await axios({
      method: "post",
      data: JSON.stringify(formData),
      url: `${urls.API_URL}/products/add-package`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    });

    return {
      done: true,
      data: res.data
    };
  } catch (error) {
    return {
      done: false
    };
  }
}

export async function getPackage(limit: number, page: number) {
  try {
    const res = await axios({
      method: "get",
      url: `${urls.API_URL}/products/get-package?limit=${limit}&page=${page}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    });

    return {
      done: true,
      data: res.data
    };
  } catch (error) {
    return {
      done: false
    };
  }
}

export async function deletePackage(packageId: string) {
  try {
    const res = await axios({
      method: "delete",
      url: `${urls.API_URL}/products/delete-package?id=${packageId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    });

    return {
      done: true,
      data: res.data
    };
  } catch (error) {
    return {
      done: false
    };
  }
}

export async function getPurchaseInfo(
  page: number,
  limit: number,
  types: string[] = [],
  onlyRefund: boolean,
  search: string
) {
  try {
    const res = await axios({
      method: "get",
      url: `${urls.API_URL}/products/purchase-history`,
      params: {
        page,
        limit,
        types,
        onlyRefund,
        search
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    });

    return {
      done: true,
      data: res.data
    };
  } catch (error) {
    return {
      done: false
    };
  }
}
