import React from "react";
import { Select } from "antd";
import { getCategories } from "../api/tags";
const { Option } = Select;

export const Categorys = () => {
  const [tgs, setTags] = React.useState([]);
  React.useEffect(() => {
    gttgs();
  }, []);

  async function gttgs() {
    const t: any = await getCategories();
    setTags(t.data.categorys);
  }

  return tgs.map((i: any, n: any) => (
    <Option key={n} value={i.name}>
      {i.name}
    </Option>
  ));
};
