import React, { useEffect, useState } from "react";
import moment from "moment";
import { PageHeader, Table, Button } from "antd";
import { getGameHistory } from "../../api/quiz";
import { MainLayout } from "../../layouts/MainLayout";
import { GameView } from "../../components/GameView";

let pageSize = 10;
export const GamePage = () => {
  let [page, setPage] = useState(0);
  let [openGameView, setOpenGameView] = useState(false);
  let [gameViewData, setGameViewData] = useState(null);
  let [loading, setLoading] = useState(true);
  let [total, setTotal] = useState(0);
  let [list, setList] = useState([]);

  useEffect(() => {
    setData();
  }, [page]);

  const setData = async () => {
    setLoading(true);
    try {
      const res = await getGameHistory(page, pageSize);
      const { total, docs } = res.data.data;
      setTotal(total);
      setList(docs);
      console.log(docs, total);
    } catch (error) {
      throw error;
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "Played Time",
      dataIndex: "playedTime",
      key: "playedTime",
      render: (date: string) => {
        return <span>{moment(date).fromNow()}</span>;
      }
    },
    {
      title: "Total Question",
      dataIndex: "questionData",
      key: "questionData",
      render: (data: any) => {
        return <span>{data.length}</span>;
      }
    },
    {
      title: "Total Users",
      dataIndex: "userData",
      key: "userData",
      render: (data: any) => {
        return <span>{data.length}</span>;
      }
    },

    {
      title: "Room Size",
      dataIndex: "roomsize",
      key: "roomsize"
    },

    {
      title: "Type",
      dataIndex: "reward_type",
      key: "reward_type",
      render: type => (type === "question_win" ? "kumbara" : "marathon")
    },

    {
      title: "Win Price",
      dataIndex: "winningprice",
      key: "winningprice"
    },
    {
      title: "Entry Fee",
      dataIndex: "entryfee",
      key: "entryfee"
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category"
    },
    {
      title: "Actions",
      key: "actions",
      render: (data: any) => {
        return (
          <Button
            icon="eye"
            type="primary"
            shape="round"
            onClick={() => {
              setGameViewData(data);
              setOpenGameView(true);
            }}
          />
        );
      }
    }
  ];
  return (
    <MainLayout>
      <PageHeader title="Game History" />

      <Table
        size="small"
        loading={loading}
        dataSource={list}
        columns={columns}
        rowKey="quizid"
        pagination={{
          total: total,
          pageSize,
          current: page + 1
        }}
        onChange={data => {
          const { current }: any = data;
          setPage(current - 1);
        }}
      ></Table>

      {openGameView && (
        <GameView
          data={gameViewData}
          close={() => {
            setOpenGameView(false);
          }}
        />
      )}
    </MainLayout>
  );
};
