import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Button,
  Table,
  Switch,
  notification,
  Avatar,
  Form,
  Modal
} from "antd";
import moment from "moment";
import ButtonGroup from "antd/lib/button/button-group";
import { AddQuizForm, IQuizAdd } from "../../components/AddQuizForm";
import { MainLayout } from "../../layouts/MainLayout";
import { getQuiz, deleteQuiz, cancelQuiz } from "../../api/quiz";
import { UserView } from "../../components/UserView";

export const Quiz = () => {
  const [openModal, setOpenModal] = useState(false);
  const [userViewOpen, setUserViewOpen] = useState(false);
  const [userList, setUserList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [onlyNew, setOnlyNew] = useState(false);
  const [list, setList] = useState<IQuizAdd[]>([]);
  const [pag, setPag] = useState({
    total: 0,
    count: 10,
    page: 0
  });

  useEffect(() => {
    getAll();
  }, [pag.page, onlyNew]);

  async function getAll() {
    setLoading(true);
    const res = await getQuiz(pag.count, pag.page, onlyNew);

    if (res.data.status) {
      if (res.data.data) {
        console.log(res.data);
        setList(res.data.data.docs);
        setPag(_ => {
          return {
            ...pag,
            total: res.data.data.count
          };
        });
      }
    }
    setLoading(false);
  }

  const deleteQ = async (id: string) => {
    await deleteQuiz(id);
    getAll();
    notification.success({
      message: "Deleted",
      description: "Successfuly deleted"
    });
  };

  const columns = [
    {
      title: "Banner",
      dataIndex: "banner",
      key: "banner",
      render: (url: string) => {
        return (
          <Avatar shape="square" size="large" src={url}>
            Quiz
          </Avatar>
        );
      }
    },
    {
      title: "Quiz will start",
      dataIndex: "starttime",
      key: "starttime",
      render: (date: string) => {
        return <span>{moment(date).fromNow()}</span>;
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status"
    },
    {
      title: "Users joined",
      dataIndex: "joinedusers",
      key: "joinedusers",
      render: (data: string[]) => {
        return <div>{data.length}</div>;
      }
    },
    {
      title: "Room Size",
      dataIndex: "roomsize",
      key: "roomsize"
    },

    {
      title: "Type",
      dataIndex: "reward_type",
      key: "reward_type",
      render: type => (type === "question_win" ? "kumbara" : "marathon")
    },
    {
      title: "Win Price",
      dataIndex: "winningprice",
      key: "winningprice"
    },
    {
      title: "Entry Fee",
      dataIndex: "entryfee",
      key: "entryfee"
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category"
    },
    {
      title: "Actions",
      key: "actions",
      render: (items: any) => (
        <span>
          <ButtonGroup size="small">
            <Button
              onClick={() => {
                setUserList(items.joinedusers);
                setUserViewOpen(true);
              }}
              type="primary"
              icon="user"
            />
            <Button
              disabled={items.status !== "pending"}
              onClick={() => {
                Modal.confirm({
                  title:
                    "The selected quiz will be canceled! Do you wish to continue?",
                  onOk: async () => {
                    const res = await cancelQuiz(items.quizid);
                    if (res.data.status) {
                      getAll();
                      notification.success({
                        message: "Canceled",
                        description: "Successfuly canceled!"
                      });
                    } else {
                      notification.error({
                        message: `The quiz can't be deleted`
                      });
                    }
                  }
                });
              }}
              type="ghost"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                Modal.confirm({
                  title:
                    "The selected quiz will be canceled! Do you wish to continue?",
                  onOk: async () => {
                    await deleteQ(items.quizid);
                  }
                });
              }}
              type="danger"
            >
              Delete
            </Button>
          </ButtonGroup>
        </span>
      )
    }
  ];

  return (
    <MainLayout>
      <PageHeader
        style={{
          padding: 0
        }}
        title="Quiz"
        subTitle="Add quiz"
      />
      <ButtonGroup
        style={{
          margin: "20px 0"
        }}
      >
        <Button
          onClick={() => {
            setOpenModal(true);
          }}
          type="primary"
          icon="plus"
        >
          Add New Quiz
        </Button>
      </ButtonGroup>

      <Form>
        <Form.Item label="Show Only pending">
          <Switch checked={onlyNew} onChange={e => setOnlyNew(e)} />
        </Form.Item>
      </Form>

      <Table
        size="small"
        loading={loading}
        dataSource={list}
        columns={columns}
        rowKey="quizid"
        pagination={{
          total: pag.total,
          pageSize: pag.count,
          current: pag.page + 1
        }}
        onChange={data => {
          const { current }: any = data;
          setPag(_ => {
            return {
              ...pag,
              page: current - 1
            };
          });
        }}
      ></Table>

      {openModal && (
        <AddQuizForm
          setisOpen={e => {
            if (!e) {
              getAll();
            }
            setOpenModal(e);
          }}
        />
      )}

      {userViewOpen && (
        <UserView
          userList={userList}
          close={() => {
            setUserViewOpen(false);
            setUserList([])
          }}
        />
      )}
    </MainLayout>
  );
};
