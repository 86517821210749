import React from "react";
import {
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Modal,
  DatePicker,
  Switch,
  notification
} from "antd";
import moment, { Moment } from "moment";
import { addPackage } from "../api/packages";

export enum PackageType {
  package = "package",
  heart = "heart",
  pass = "pass",
  key = "key",
  two_asnwer = "two_asnwer",
  fifty_fifty = "fifty_fifty"
}

export enum PurchaseMode {
  coin = "coin",
  money = "money"
}

export interface IPackage {
  readonly packageId: string;
  readonly title: string;
  readonly heartCount: number;
  readonly passCount: number;
  readonly fiftyFiftyCount: number;
  readonly twoAnswerCount: number;
  readonly keyCount: number;
  readonly purchaseMode: PurchaseMode;
  readonly price: number;
  readonly priceSold: number;
  readonly type: PackageType;
  readonly isOffer: boolean;
  readonly discountPercent: number;
  readonly validTill?: string;
  readonly _id?: string;
}

export const AddNewPackageForm = ({ close, initValue }) => {
  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState<any>(initValue);

  const update = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    setForm((f: any) => ({ ...f, [name]: value }));
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const res = await addPackage(form);

    if (res.done) {
      if (res.data.status) {
        notification.success({
          message: res.data.message
        });

        setLoading(false);
        close();
      } else {
        notification.success({
          message: res.data.message
        });
      }
    } else {
      notification.error({
        message: "Something went wrong check your connection"
      });
    }

    setLoading(false);
  };

  return (
    <Modal
      visible={true}
      destroyOnClose={true}
      onCancel={close}
      footer={[
        <Button
          form="packageForm"
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Add Package
        </Button>
      ]}
    >
      <form id="packageForm" onSubmit={onSubmit}>
        <Form.Item label="ProductId" required>
          <Input
            name="packageId"
            type="text"
            required
            onChange={update}
            defaultValue={form.packageId}
          />
        </Form.Item>

        <Form.Item label="Title" required>
          <Input
            name="title"
            type="text"
            required
            onChange={update}
            defaultValue={form.title}
          />
        </Form.Item>

        <Form.Item label="Heart Count">
          <InputNumber
            name="heartCount"
            min={0}
            defaultValue={form.heartCount}
            onChange={e => {
              update({
                target: {
                  name: "heartCount",
                  value: e
                }
              });
            }}
          />
        </Form.Item>

        <Form.Item label="Pass Count">
          <InputNumber
            name="passCount"
            min={0}
            defaultValue={form.passCount}
            onChange={e => {
              update({
                target: {
                  name: "passCount",
                  value: e
                }
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Fifty Fifty Count">
          <InputNumber
            name="fiftyFiftyCount"
            min={0}
            defaultValue={form.fiftyFiftyCount}
            onChange={e => {
              update({
                target: {
                  name: "fiftyFiftyCount",
                  value: e
                }
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Two Answer Count">
          <InputNumber
            name="twoAnswerCount"
            min={0}
            defaultValue={form.twoAnswerCount}
            onChange={e => {
              update({
                target: {
                  name: "twoAnswerCount",
                  value: e
                }
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Key Count">
          <InputNumber
            name="keyCount"
            min={0}
            defaultValue={form.keyCount}
            onChange={e => {
              update({
                target: {
                  name: "keyCount",
                  value: e
                }
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Purchase Mode" required>
          <Select
            defaultValue={form.purchaseMode}
            onChange={e => {
              console.log(e);
              setForm((f: any) => ({ ...f, purchaseMode: e }));
            }}
          >
            <Select.Option value="coin">Coin</Select.Option>
            <Select.Option value="money">Money</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Price" required>
          <InputNumber
            name="price"
            min={0}
            required
            defaultValue={form.price}
            onChange={e => {
              update({
                target: {
                  name: "price",
                  value: e
                }
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Price Sold (Actual price user will pay)" required>
          <InputNumber
            name="priceSold"
            min={0}
            required
            defaultValue={form.priceSold}
            onChange={e => {
              update({
                target: {
                  name: "priceSold",
                  value: e
                }
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Type" required>
          <Select
            defaultValue={form.type}
            onChange={e => {
              console.log(e);
              setForm((f: any) => ({ ...f, type: e }));
            }}
          >
            <Select.Option value="package">Package</Select.Option>
            <Select.Option value="key">Key</Select.Option>
            <Select.Option value="heart">Heart</Select.Option>
            <Select.Option value="pass">Pass</Select.Option>
            <Select.Option value="two_answer">Two Answer</Select.Option>
            <Select.Option value="fifty_fifty">Fifty Fifty</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Offer">
          <Switch
            defaultChecked={form.isOffer}
            onChange={e => {
              update({
                target: {
                  name: "isOffer",
                  value: e
                }
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Discount Percent (in %)">
          <InputNumber
            name="discountPercent"
            min={0}
            defaultChecked={form.discountPercent}
            onChange={e => {
              update({
                target: {
                  name: "discountPercent",
                  value: e
                }
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Valid Till" required>
          {form.validTill ? (
            <DatePicker
              showTime={true}
              //defaultValue={}
              value={moment(form.validTill)}
              onChange={(m: Moment | null, _) => {
                console.log(m);
                if (m) {
                  update({
                    target: {
                      name: "validTill",
                      value: m.utc().valueOf()
                    }
                  });
                } else {
                  update({
                    target: {
                      name: "validTill",
                      value: ""
                    }
                  });
                }
              }}
            />
          ) : (
            <DatePicker
              showTime={true}
              onChange={(m: Moment | null, _) => {
                console.log(m);
                if (m) {
                  update({
                    target: {
                      name: "validTill",
                      value: m.utc().valueOf()
                    }
                  });
                } else {
                  update({
                    target: {
                      name: "validTill",
                      value: ""
                    }
                  });
                }
              }}
            />
          )}
        </Form.Item>
      </form>
    </Modal>
  );
};
