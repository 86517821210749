import axios from "./axios";
import urls from "../config/url";
import { AuthService } from "../config/auth";
import { IListOptions, ITransactionStatus } from "../pages/pay";

export async function getAll(Options: IListOptions) {
  const url = new URL(`${urls.API_URL}/transaction/list_all`);
  url.searchParams.set("limit", Options.limit.toString());
  url.searchParams.set("page", Options.page.toString());
  url.searchParams.set("showPaid", Options.showPaid.toString());
  url.searchParams.set("showUnPaid", Options.showUnPaid.toString());
  url.searchParams.set("showCanceled", Options.showCanceled.toString());
  url.searchParams.set("showFailed", Options.showFailed.toString());

  console.log(Options);
  const res = await axios({
    method: "get",
    url: url.href,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });

  return res;
}

export async function mark(
  transactionId: string,
  status: ITransactionStatus,
  message: string
) {
  const url = new URL(`${urls.API_URL}/transaction/mark`);
  url.searchParams.set("transactionId", transactionId);
  url.searchParams.set("status", status);
  url.searchParams.set("message", message);

  const res = await axios({
    method: "post",
    url: url.href,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });

  return res;
}
