import React, { useState, useReducer } from "react";
import {
  Form,
  Drawer,
  DatePicker,
  Select,
  InputNumber,
  notification,
  Button,
  Upload,
  Avatar
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { QuestionSelector } from "./QuestionSelector";
import { AutoQuestionSelector } from "./AutoQuestionsSelector";
import ButtonGroup from "antd/lib/button/button-group";
import { addQuiz, } from "../api/quiz";
import { Moment } from "moment";
import { Categorys } from "./Categorys";
import { Loader } from "./Loader";
import { ImageSelector } from "./ImageSelector";

interface IAddQuizForm {
  setisOpen: (a: boolean) => any;
}

export interface IQuizAdd {
  starttime: string;
  roomsize: number;
  winningprice: number;
  entryfee: number;
  //type: string;
  category: string;
  reward_type: string;
  questionlist: string[];
  joinedusers?: string[];
  banner: any;
}

const initState: IQuizAdd = {
  starttime: "",
  roomsize: 0,
  winningprice: 0.0,
  entryfee: 0.0,
  //type: "live",
  category: "",
  reward_type: "game_win",
  questionlist: [],
  banner: ""
};

const formReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE":
      return Object.assign({}, state, {
        [action.key]: action.value
      });
    case "RESET":
      return initState;
    default:
      return state;
  }
};

export const AddQuizForm: React.FC<IAddQuizForm> = ({ setisOpen }) => {
  const [state, dis] = useReducer(formReducer, initState);
  const [qType, setQType] = useState("manual");
  const [imgType, setImgType] = useState("new");
  const [loading, setLoading] = useState(false);
  const [openImageSelector, setOpenImageSelector] = useState(false);

  const OptTags = Categorys();

  let res: any;
  const submitQuiz = async () => {
    setLoading(true);
    res = await addQuiz(state, imgType);

    if (res.status) {
      notification.open({
        message: "Quiz Added",
        description: res.msg
      });
      setLoading(false);
      setisOpen(false);
    } else {
      notification.open({
        message: "Failed",
        description: res.msg
      });
      setLoading(false);
    }
  };

  return (
    <Drawer
      title="Add new quiz"
      placement="right"
      destroyOnClose
      visible
      width="100%"
      onClose={() => {
        console.log("fired");
        dis({
          type: "RESET"
        });
        setisOpen(false);
      }}
    >
      {loading && <Loader />}
      <Form
        layout="vertical"
        style={{
          maxWidth: "400px",
          margin: "auto"
        }}
      >
        <Form.Item label="Start Date">
          <DatePicker
            showTime={true}
            onChange={(m: Moment | null, _) => {
              if (m) {
                dis({
                  type: "UPDATE",
                  key: "starttime",
                  value: m.valueOf()
                });
              }
            }}
            style={{
              width: "100%"
            }}
          />
        </Form.Item>
        <Form.Item label="Room Size">
          <InputNumber
            value={state.roomsize}
            onChange={(value: any) => {
              dis({
                type: "UPDATE",
                key: "roomsize",
                value: value
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Winning Price">
          <InputNumber
            value={state.winningprice}
            onChange={(value: any) => {
              dis({
                type: "UPDATE",
                key: "winningprice",
                value: value
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Reward Type">
          <Select
            value={state.reward_type}
            onChange={(e: any) => {
              dis({
                type: "UPDATE",
                key: "reward_type",
                value: e
              });
            }}
          >
            <Select.Option value="game_win">Marathon</Select.Option>
            <Select.Option value="question_win">Kumbara</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Entry Fee">
          <InputNumber
            value={state.entryfee}
            onChange={(value: any) => {
              dis({
                type: "UPDATE",
                key: "entryfee",
                value: value
              });
            }}
          />
        </Form.Item>
        {
          //<Form.Item label="Quiz Type">
          //<Select
          //value={state.type}
          //onChange={(e: any) => {
          //dis({
          //type: "UPDATE",
          //key: "type",
          //value: e
          //});
          //}}
          //>
          //<Select.Option value="live">LIVE</Select.Option>
          //<Select.Option value="reward">REWARD</Select.Option>
          //</Select>
          //</Form.Item>
        }
        <Form.Item label="Quiz Categories">
          <Select
            value={state.category}
            onChange={(e: any) => {
              dis({
                type: "UPDATE",
                key: "category",
                value: e
              });
            }}
          >
            {OptTags}
          </Select>
        </Form.Item>
        <Form.Item label="Quiz Image">
          <Upload
            //fileList={[state.banner]}
            beforeUpload={(file: any) => {
              console.log(file);
              setImgType("new");
              dis({
                type: "UPDATE",
                key: "banner",
                value: file
              });
              return false;
            }}
            name="logo"
            listType="picture"
          >
            <Button>
              <UploadOutlined /> Click to upload
            </Button>
          </Upload>
          <span
            style={{
              width: "100%",
              display: "block",
              textAlign: "left",
              margin: "10px 0"
            }}
          >
            OR
          </span>
          <Button onClick={() => setOpenImageSelector(true)}>
            <UploadOutlined /> Select from the List
          </Button>
          {imgType === "old" && (
            <Avatar src={state.banner} size={64} shape="square" />
          )}

          {openImageSelector && (
            <>
              <ImageSelector
                onSelect={(uri: string) => {
                  setImgType("old");

                  dis({
                    type: "UPDATE",
                    key: "banner",
                    value: uri
                  });
                  setOpenImageSelector(false);
                }}
                close={() => setOpenImageSelector(false)}
              />
            </>
          )}
        </Form.Item>

        <Form.Item label="Add Questions type">
          <Select
            value={qType}
            onChange={(e: any) => {
              setQType(e);
              dis({
                type: "UPDATE",
                key: "questionlist",
                value: []
              });
            }}
          >
            <Select.Option value="manual">Manually</Select.Option>
            <Select.Option value="auto">
              Auto (New Questions first)
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Questions list">
          {qType === "manual" ? (
            <QuestionSelector
              updateList={list => {
                if (state.questionlist.length >= 10) {
                  notification.error({
                    message: "Failed to add",
                    description: "10 answer is the limit!"
                  });
                  return;
                }

                dis({
                  type: "UPDATE",
                  key: "questionlist",
                  value: list
                });
              }}
            />
          ) : (
            <AutoQuestionSelector
              updateList={list => {
                dis({
                  type: "UPDATE",
                  key: "questionlist",
                  value: list
                });
              }}
            />
          )}
        </Form.Item>
        <Form.Item>
          <ButtonGroup>
            <Button type="primary" icon="submit" onClick={submitQuiz}>
              Submit
            </Button>
          </ButtonGroup>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
