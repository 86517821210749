import React from "react";
import { Modal, Table, Tag, Tree } from "antd";
import {CaretRightOutlined} from "@ant-design/icons";

const AddBulkQuestions: React.FC<any> = ({ data, close, onAddBulk }) => {
  console.log(data);
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title"
    },
    {
      title: "Options",
      dataIndex: "options",
      key: "options",
      render: (options: any) => {
        let treeData = [
          {
            title: "Options",
            key: "0-0",
            children: options.map((item: any) => ({
              title: `${item.key}: ${item.value}`,
              key: item.key,
              icon: <CaretRightOutlined/>
            }))
          }
        ];

        return <Tree treeData={treeData} />;
      }
    },
    {
      title: "Answer Key",
      dataIndex: "answerKey",
      key: "answerKey"
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      render: (tag: any) => {
        let color = "blue";
        switch (tag) {
          case "practice":
            color = "green";
            break;
          case "easy":
            color = "blue";
            break;
          case "medium":
            color = "yellow";
            break;
          case "hard":
            color = "red";
            break;
        }
        if (tag === "loser") {
          color = "volcano";
        }

        return (
          <Tag color={color} key={tag}>
            {tag.toUpperCase()}
          </Tag>
        );
      }
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (tag: string[]) => {
        return (
          <div>
            {tag.map(name => {
              return <Tag key={name}>{name}</Tag>;
            })}
          </div>
        );
      }
    }
  ];

  return (
    <Modal
      title="Bulk Questions"
      visible
      onCancel={close}
      closable
      onOk={async () => await onAddBulk(data)}
    >
      <Table rowKey="title" dataSource={data} columns={columns} />
    </Modal>
  );
};

export default AddBulkQuestions;
