import _axios from "axios";
import history from "../config/histroy";
import { notification } from "antd";

const axios = _axios.create();

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    if (error.response) {
      if (error.response.status === 403) {
        localStorage.clear();
        history.push("/");
        notification.error({
          message:
            "Your account settings might have changed! Please contact Admin or try login again"
        });
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
