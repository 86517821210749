import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthService } from "../../config/auth";
import { Form, Input, Select, Button, notification } from "antd";
import { login, verify } from "../../api/user";
const { Option } = Select;

export const Auth = () => {
  const [mobile, setMobile] = useState("");
  const [cc, setCc] = useState("+91");
  const [otp, setOtp] = useState("");
  const [pass, setPass] = useState("");
  const [otpScreen, setOtpScreen] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (AuthService.isAuth()) {
      history.push("/home");
    }
  });

  const sentOtp = async () => {
    setBtnLoading(true);

    // sent otp
    console.log(cc + mobile);
    const _cc = cc.replace("+", "%2B");
    let value: any;

    try {
      value = await login(_cc + mobile);
    } catch (error) {
      notification.error({
        message: "Failed",
        description: "Please check your number and try again"
      });

      setBtnLoading(false);
      return;
    }

    if (value.data.status) {
      if (value.data.data.otp) {
        setOtp(value.data.data.otp);
      }
      setBtnLoading(false);
      setOtpScreen(true);
      notification.success({
        message: "Otp sent ",
        description: "Successfuly sent the otp"
      });
    } else {
      notification.error({
        message: "Error",
        description: value.data.message
      });
    }
    setBtnLoading(false);
    console.log(value.data);
  };

  const verifyOtp = async (e: any) => {
    e.preventDefault();
    setBtnLoading(true);

    const _cc = cc.replace("+", "%2B");
    let value: any;
    try {
      value = await verify(otp, _cc + mobile, pass);
      if (value.data.status) {
        if (value.data.data.role === "user") {
          notification.error({
            message: "UNAUTHORIZED"
          });
          setBtnLoading(false);
          return;
        }

        await AuthService.setToken(value.data.data.api_token);
        AuthService.setRole(value.data.data.role);

        setBtnLoading(false);

        notification.success({
          message: "Done",
          description: "Welcome"
        });
        history.push("/home");
      } else {
        notification.error({
          message: "Error",
          description: value.data.message
        });
        setBtnLoading(false);
      }
    } catch (error) {
      notification.error({
        message: "UNAUTHORIZED"
      });
      setBtnLoading(false);
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      {otpScreen ? (
        <Form layout="horizontal" onSubmit={verifyOtp}>
          <Form.Item label="Enter OTP" required>
            <Input
              value={otp}
              placeholder="OTP"
              onChange={e => {
                setOtp(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Enter Password" required>
            <Input.Password
              value={pass}
              placeholder="Password"
              onChange={e => {
                setPass(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button loading={btnLoading} type="primary" htmlType="submit">
              VEIRFY
            </Button>

            <Button
              style={{
                marginLeft: "5px"
              }}
              type="ghost"
              onClick={() => setOtpScreen(false)}
            >
              RESEND OTP
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Form>
          <Form.Item label="Phone Number">
            <Input
              onChange={e => {
                setMobile(e.target.value);
              }}
              required
              value={mobile}
              addonBefore={
                <Select
                  defaultValue={cc}
                  onChange={(e: any) => {
                    setCc(e);
                  }}
                  style={{ width: 70 }}
                >
                  <Option value="+91">+91</Option>
                  <Option value="+90">+90</Option>
                </Select>
              }
              style={{ width: "100%" }}
            />
            <Form.Item>
              <Button loading={btnLoading} onClick={sentOtp} type="primary">
                SEND OTP
              </Button>
            </Form.Item>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};
