import React, { useState, useEffect } from "react";
import { Dropdown, Layout, Avatar, Menu, Button, Affix } from "antd";
import "./navbar.css";
import { AuthService } from "../config/auth";
import { getInfo } from "../api/user";
import { useHistory } from "react-router-dom";

export const Navbar = () => {
  const history = useHistory();

  const menu = (
    <Menu>
      <Menu.Item key="2">
        <Button
          type="link"
          onClick={e => {
            e.preventDefault();
            AuthService.logout();
            history.push("/");
          }}
        >
          Log Out
        </Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <Layout.Header
      className="nav-main"
      //style={{ position: "fixed", zIndex: 1, width: "100%" }}
    >
      <div className="nav-inner">
        <Dropdown overlay={menu} trigger={["click"]}>
          <div className="nav-box">
            <Avatar
              src={localStorage.getItem("avatar") || ""}
              style={{ backgroundColor: "#87d068" }}
              size="small"
            />{" "}
            <p>{localStorage.getItem("name")}</p>
          </div>
        </Dropdown>
      </div>
    </Layout.Header>
  );
};
