import React from "react";
import { PageHeader } from "antd";
import { MainLayout } from "../../layouts/MainLayout";

export const Home = () => {
  return (
    <MainLayout>
      <PageHeader title="Home" />
      <h1>Home</h1>
    </MainLayout>
  );
};
