import React, { useState, useEffect } from "react";
import { Modal, Input, Form, Select, notification, Button } from "antd";
import p from "immer";
import { IQuestionData } from "../pages/questions";
import { Tags } from "./Tags";
import { Loader } from "./Loader";

interface IQForm {
  isOpen: boolean;
  setisOpen: (b: boolean) => any;
  onAdd: (item: IQuestionData) => any;
  as: "add" | "update";
  initVals?: IQuestionData;
}

export const _initVals: IQuestionData = {
  title: "",
  options: [
    {
      key: 1,
      value: ""
    },
    {
      key: 2,
      value: ""
    },
    {
      key: 3,
      value: ""
    },
    {
      key: 4,
      value: ""
    }
  ],
  answerKey: 1,
  level: "practice",
  tags: [],
  percentage: 0
};

export const AddQuestionForm: React.FC<IQForm> = ({
  isOpen,
  setisOpen,
  onAdd,
  as,
  initVals = _initVals
}) => {
  const [data, setData] = useState<IQuestionData>(initVals);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData(initVals);
  }, [initVals]);

  const OptTags = Tags();

  let modal_title = "";

  switch (as) {
    case "add":
      modal_title = "Add new question";
      break;
    case "update":
      modal_title = "Update question";
      break;
  }

  //const resetQuestion = (qid: string) => {
  //// reset
  //setLoading(true);
  //console.log(qid);

  //setTimeout(() => {
  //notification.info({
  //message: "Question reset"
  //});
  //setLoading(false);
  //}, 2000);
  //};

  return (
    <Modal
      destroyOnClose
      title={modal_title}
      visible={isOpen}
      onOk={() => {
        const optValEmpty = data.options.some(item => item.value === "");
        if (data.title === "" || optValEmpty) {
          notification.error({
            message: "Can't be empty",
            description: "Check your title and options they can't be empty"
          });

          return;
        }

        console.log(data.title.length);
        if (data.title.length <= 3) {
          notification.error({
            message: "Title too short",
            description: "Please make sure you title in greater then 3-4 chars"
          });
          return;
        }

        // validations
        onAdd(data);
      }}
      onCancel={() => {
        setisOpen(false);
      }}
    >
      {loading && <Loader />}
      <Form layout="horizontal">
        <Form.Item label="Question">
          <Input
            placeholder="Enter you question"
            minLength={5}
            value={data.title}
            type="text"
            onChange={e => {
              const nextState = p(data, draft => {
                draft.title = e.target.value;
              });
              setData(nextState);
            }}
          />
        </Form.Item>
        <Form.Item label="Options">
          <Input
            type="text"
            placeholder="Option 1"
            value={data.options[0].value}
            onChange={e => {
              const nextState = p(data, draft => {
                draft.options[0].value = e.target.value;
              });
              setData(nextState);
            }}
          />
          <Input
            type="text"
            placeholder="Option 2"
            value={data.options[1].value}
            onChange={e => {
              const nextState = p(data, draft => {
                draft.options[1].value = e.target.value;
              });
              setData(nextState);
            }}
          />
          <Input
            type="text"
            placeholder="Option 3"
            value={data.options[2].value}
            onChange={e => {
              const nextState = p(data, draft => {
                draft.options[2].value = e.target.value;
              });
              setData(nextState);
            }}
          />
          <Input
            type="text"
            placeholder="Option 4"
            value={data.options[3].value}
            onChange={e => {
              const nextState = p(data, draft => {
                draft.options[3].value = e.target.value;
              });
              setData(nextState);
            }}
          />
        </Form.Item>

        <Form.Item label="Answer">
          <Select
            value={data.answerKey}
            onChange={(e: any) => {
              const nextState = p(data, draft => {
                draft.answerKey = e;
              });
              setData(nextState);
            }}
          >
            <Select.Option value={1}>Options 1</Select.Option>
            <Select.Option value={2}>Options 2</Select.Option>
            <Select.Option value={3}>Options 3</Select.Option>
            <Select.Option value={4}>Options 4</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Level">
          <Select
            value={data.level}
            onChange={(e: any) => {
              const nextState = p(data, draft => {
                draft.level = e;
              });
              setData(nextState);
            }}
          >
            <Select.Option value="practice">Pratice</Select.Option>
            <Select.Option value="easy">Easy</Select.Option>
            <Select.Option value="medium">Medium</Select.Option>
            <Select.Option value="hard">Hard</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="tags">
          <Select
            defaultValue={data.tags}
            mode="tags"
            onChange={(e: any) => {
              console.log(e);
              const nextState = p(data, draft => {
                draft.tags = e;
              });
              setData(nextState);
            }}
          >
            {OptTags}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

//{as === "update" && data.percentage && data.percentage !== 0 ? (
//<Form.Item>
//<Button
//type="primary"
//onClick={() => {
//if (data.questionid) {
//resetQuestion(data.questionid);
//}
//}}
//>
//Reset Question
//</Button>
//</Form.Item>
//) : (
//<span>New Question</span>
//)}
