import React from "react";
import { Spin } from "antd";

export const Loader = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: "#ffffffa8",
        display: "flex",
        justifyContent: "center",
        zIndex: 1000,
        alignItems: "center"
      }}
    >
      <Spin size="large" />
    </div>
  );
};
