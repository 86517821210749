import React, { useState, useEffect, useCallback } from "react";
import {
  Input,
  Tag,
  PageHeader,
  Table,
  Button,
  notification,
  Divider,
  Rate,
  Badge,
  Tree,
  Checkbox,
  Slider,
  Typography
} from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import { parse } from "papaparse";

import { CaretRightOutlined } from "@ant-design/icons";

import { AddQuestionForm, _initVals } from "../../components/addQuestionsForm";
import {
  updateQuestion,
  addQuestion,
  getQuestions,
  removeQuestion,
  addQuestionBulk,
  resetQuestion as _resetQuestion
} from "../../api/questions";
import { MainLayout } from "../../layouts/MainLayout";
import AddBulkQuestions from "../../components/AddBulkQuestions";

export interface IOption {
  key: number;
  value: string;
}

export interface IQuestionData {
  title: string;
  options: IOption[];
  answerKey: number;
  questionid?: string;
  level: "practice" | "easy" | "hard" | "medium";
  win?: number;
  played?: number;
  tags: string[];
  percentage?: number;
}

export const Questions = () => {
  const count = 10;
  const [addModal, setAddModal] = useState(false);
  const [addBulkModal, setAddBulkModal] = useState(false);
  const [bulkData, setBulkData] = useState<any[]>([]);
  const [table, setTable] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  const [range, setRange] = useState({
    enable: false,
    value: 100
  });

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [onlyNew, setOnlyNew] = useState(0);
  const [updateModal, setUpdateModal] = useState(false);
  const [forUpdate, setForUpdate] = useState<IQuestionData>(_initVals);

  useEffect(() => {
    function ref() {
      setLoading(true);
      getQuestions(
        page,
        count,
        search,
        null,
        onlyNew,
        range.enable,
        range.value
      )
        .then(res => {
          if (res.status) {
            const data = res.data;
            console.log(data);
            setTable(data.questions);
            setTotal(data.total);
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          console.log(`Done`);
          setLoading(false);
        });
    }
    ref();
  }, [page, count, search, onlyNew, range.enable]);

  function refresh() {
    setLoading(true);
    getQuestions(page, count, search, null, onlyNew, range.enable, range.value)
      .then(res => {
        if (res.status) {
          const data = res.data;
          console.log(data);
          setTable(data.questions);
          setTotal(data.total);
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        console.log(`Done`);
        setLoading(false);
      });
  }

  const onAdd = async (formData: IQuestionData) => {
    console.log(formData);
    const ans = await addQuestion(formData);

    if (ans.status) {
      notification.success({
        message: "Added",
        description: "Successfuly added question"
      });
      refresh();
      setAddModal(false);
    } else {
      notification.error({
        message: "Failed to add",
        description: "Server Error"
      });
    }
  };

  const onAddBulk = async (formData: IQuestionData[]) => {
    console.log(formData);
    const ans = await addQuestionBulk(formData);

    if (ans.status) {
      notification.success({
        message: "Added",
        description: "Successfuly added questions"
      });
      refresh();
      setAddBulkModal(false);
    } else {
      notification.error({
        message: "Failed to add",
        description: "Server Error"
      });
    }
  };

  const deleteQuestion = async (data: IQuestionData) => {
    const yes = window.confirm("The quetions will be deleted are you sure?");
    console.log(yes);
    console.log(data);

    if (yes && data.questionid) {
      const res = await removeQuestion(data.questionid);
      if (res.status) {
        notification.success({
          message: "Question delete",
          description: `Successfuly delete question with id ${data.questionid}`
        });

        refresh();
      } else {
        notification.error({
          message: "Failed to delete",
          description: "Server Error"
        });
      }
    }
  };

  const onUpdate = async (data: IQuestionData) => {
    const res = await updateQuestion(data);
    if (res.status) {
      notification.success({
        message: "Question updated",
        description: `Successfuly updated question`
      });

      refresh();
    } else {
      notification.error({
        message: "Failed to update",
        description: "Server Error"
      });
    }

    setUpdateModal(false);
  };

  const resetQuestion = async (quiz: IQuestionData) => {
    if (quiz.questionid) {
      const res = await _resetQuestion(quiz.questionid);
      if (res.status) {
        notification.success({
          message: "Question updated",
          description: `Successfuly reseted the question`
        });

        refresh();
      } else {
        notification.error({
          message: "Failed to update",
          description: "Server Error"
        });
      }
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title"
    },
    {
      title: "Options",
      dataIndex: "options",
      key: "options",
      render: (options: any) => {
        let treeData = [
          {
            title: "Options",
            key: "0-0",
            children: options.map((item: any) => ({
              title: `${item.key}: ${item.value}`,
              key: item.key,
              icon: <CaretRightOutlined />
            }))
          }
        ];

        return <Tree treeData={treeData} />;
      }
    },
    {
      title: "Answer Key",
      dataIndex: "answerKey",
      key: "answerKey"
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      render: (tag: any) => {
        let color = "blue";
        switch (tag) {
          case "practice":
            color = "green";
            break;
          case "easy":
            color = "blue";
            break;
          case "medium":
            color = "yellow";
            break;
          case "hard":
            color = "red";
            break;
        }
        if (tag === "loser") {
          color = "volcano";
        }

        return (
          <Tag color={color} key={tag}>
            {tag.toUpperCase()}
          </Tag>
        );
      }
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (tag: string[]) => {
        return (
          <div>
            {tag.map(name => {
              return <Tag key={name}>{name}</Tag>;
            })}
          </div>
        );
      }
    },
    {
      title: "Percentage %",
      key: "per",
      render: (item: any) => {
        if (item.played > 0) {
          return <span> {((item.win / item.played) * 100).toFixed(2)} </span>;
        } else {
          return (
            <Badge title="New Question">
              <Rate count={1} value={1} />
            </Badge>
          );
        }
      }
    },
    {
      title: "Wins",
      dataIndex: "win",
      key: "win"
    },
    {
      title: "Played",
      dataIndex: "played",
      key: "played"
    },
    {
      title: "Actions",
      key: "actions",
      render: (items: IQuestionData) => (
        <span>
          <ButtonGroup size="small">
            <Button
              type="primary"
              onClick={() => {
                setUpdateModal(true);
                setForUpdate(() => items);
              }}
            >
              Update
            </Button>
            {
              /* 
            // @ts-ignore */
              items.played > 0 && (
                <Button
                  type="ghost"
                  onClick={() => {
                    resetQuestion(items);
                  }}
                >
                  Reset
                </Button>
              )
            }

            <Button onClick={() => deleteQuestion(items)} type="danger">
              Delete
            </Button>
          </ButtonGroup>
        </span>
      )
    }
  ];

  return (
    <MainLayout>
      <PageHeader
        style={{
          padding: 0
        }}
        title="Questions"
        subTitle="Add and remove questions for the quiz."
      />
      <ButtonGroup
        style={{
          margin: "20px 0"
        }}
      >
        <Button
          onClick={() => {
            setAddModal(true);
          }}
          type="primary"
          icon="plus"
          style={{
            marginRight: "10px"
          }}
        >
          Add New Question
        </Button>
        <Typography.Text>OR</Typography.Text>
        <input
          style={{
            marginLeft: "10px"
          }}
          accept=".csv"
          type="file"
          onChange={data => {
            const currFile = data.target.files[0];
            parse(currFile, {
              dynamicTyping: true,
              complete: function (results) {
                // @ts-ignore
                const headers = Array.from(results.data[0]);
                const validHeaders = [
                  "title",
                  "option1",
                  "option2",
                  "option3",
                  "option4",
                  "answerKey",
                  "level",
                  "tags"
                ];

                if (headers.length !== validHeaders.length) {
                  notification.error({
                    message: "Error in parse the csv file!"
                  });
                  return;
                } else {
                  const allList = [];
                  results.data.forEach((item, i) => {
                    // @ts-ignore
                    if (i !== 0 || item.length !== validHeaders.length) {
                      const qData = {};
                      let qKey = 1;
                      qData["options"] = [];
                      // @ts-ignore
                      item.forEach((value: any, j: number) => {
                        let _key = validHeaders[j];
                        if (
                          ["option1", "option2", "option3", "option4"].includes(
                            _key
                          )
                        ) {
                          qData["options"].push({
                            key: qKey,
                            value: String(value).trim()
                          });
                          qKey += 1;
                        } else if (_key === "tags") {
                          const _value = value
                            .split(",")
                            .map((a: string) => a.toLowerCase().trim());
                          qData[_key] = _value;
                        } else {
                          qData[_key] = String(value).trim();
                        }

                        console.log(`key is [${_key}] value is [${value}]`);
                      });
                      console.log(qData);
                      if (Object.values(qData).length === 5) {
                        allList.push(qData);
                      }
                    }
                  });
                  setAddBulkModal(true);
                  setBulkData(allList);
                }
              }
            });
          }}
        ></input>
      </ButtonGroup>

      <Input.Search
        placeholder="Search question"
        enterButton="Search"
        allowClear
        onSearch={value => {
          console.log(value);
          setSearch(value);
        }}
      />
      <div
        style={{
          height: "10px"
        }}
      ></div>
      <Checkbox
        checked={onlyNew === 1}
        onChange={(e: any) => {
          console.log(e.target.checked);
          setOnlyNew(e.target.checked ? 1 : 0);
        }}
      >
        Show only new questions
      </Checkbox>

      <Checkbox
        checked={range.enable}
        onChange={(e: any) => {
          setRange(s => {
            return {
              ...s,
              enable: e.target.checked
            };
          });
        }}
      >
        Enable Range Filter
      </Checkbox>
      {range.enable && (
        <Slider
          style={{
            width: "100px"
          }}
          max={100}
          value={range.value}
          min={0}
          onChange={(e: number) => {
            setRange(s => {
              return {
                ...s,
                value: e
              };
            });
          }}
          onAfterChange={() => {
            refresh();
          }}
        />
      )}
      <Divider />
      <Table
        pagination={{
          total,
          pageSize: count,
          current: page
        }}
        loading={loading}
        dataSource={table}
        columns={columns}
        rowKey="questionid"
        onChange={data => {
          const { current }: any = data;
          setPage(current);
        }}
        size="small"
      ></Table>

      {addModal && (
        <AddQuestionForm
          isOpen={addModal}
          as="add"
          setisOpen={(b: boolean) => setAddModal(b)}
          onAdd={(item: IQuestionData) => onAdd(item)}
        />
      )}

      {addBulkModal && (
        <AddBulkQuestions
          as="add1"
          data={bulkData}
          close={() => setAddBulkModal(false)}
          onAddBulk={onAddBulk}
        />
      )}

      <AddQuestionForm
        isOpen={updateModal}
        as="update"
        setisOpen={(b: boolean) => setUpdateModal(b)}
        onAdd={(item: IQuestionData) => onUpdate(item)}
        initVals={forUpdate}
      />
    </MainLayout>
  );
};
