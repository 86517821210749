import React from "react";
import { Layout } from "antd";
import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";

export const MainLayout: React.FC = ({ children }) => {
  return (
    <Layout
      style={{
        height: "100vh"
      }}
    >
      <Sidebar />
      <Layout>
        <Navbar />

        <Layout.Content style={{ margin: "24px 16px 0" }}>
          <div style={{ padding: 24, background: "#fff", minHeight: "100vh" }}>
            {children}
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};
