import React from "react";
import { List, Avatar, Modal, Rate, Table } from "antd";

interface IGameView {
  data: any;
  close: () => any;
}

export const GameView: React.FC<IGameView> = ({ data, close }) => {
  console.log(data);
  return (
    <Modal title="GameView" visible onCancel={close} closable>
      <List
        itemLayout="horizontal"
        dataSource={data.questionData}
        renderItem={(item: any) => (
          <List.Item>
            Title: {item.title}
            <br />
            Option 1: {item.option1}
            <br />
            Option 2: {item.option2}
            <br />
            Option 3: {item.option3}
            <br />
            Option 4: {item.option4}
            <br />
            Correct: {item.correct}
          </List.Item>
        )}
      />
      <List
        itemLayout="horizontal"
        dataSource={data.userData}
        renderItem={(item: any) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={item.avatar} />}
              title={<a href="https://ant.design">{item.username}</a>}
              description={`UserId: ${item.userid}`}
            />
            Question Scores:{" "}
            {item.correctGiven.map((i: any, key: any) => (
              <Rate key={key} disabled count={1} value={i} />
            ))}
            <br />
            Choice:{" "}
            {item.answerGiven.map((i: any, key: any) => (
              <Rate key={key} character={i} disabled count={1} value={1} />
            ))}
            <br />
            {Object.keys(item.powerUpUsed).map(key => {
              return (
                <i key={key}>
                  {key.toUpperCase()} Used: {item.powerUpUsed[key]}
                  <br />
                </i>
              );
            })}
            {item.powerUpUsedNo.map(({ productid, qNo }, key) => {
              return (
                <i key={key}>
                  question no: {qNo} productId: {productid}
                  <br />
                </i>
              );
            })}
          </List.Item>
        )}
      />
    </Modal>
  );
};
