import React, { useState, useEffect, useRef } from "react";
import {
  PageHeader,
  Button,
  Table,
  Tag,
  Form,
  Checkbox,
  Modal,
  Typography,
  Input
} from "antd";
import { MainLayout } from "../../layouts/MainLayout";
import { getAll, mark } from "../../api/transactions";
import ButtonGroup from "antd/lib/button/button-group";
import moment from "moment";
const { Paragraph } = Typography;

export type ITransactionStatus = 'paid' | 'pending' | 'canceled' | 'failed';

export interface IListOptions {
  page: number;
  limit: number;
  showPaid: string;
  showUnPaid: string;
  showCanceled: string;
  showFailed: string;
}

export const PayPage = () => {
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [list, setList] = useState<any[]>([]);
  const canceledRef = useRef<any>("");
  const payRef = useRef<any>("");
  const failRef = useRef<any>("");
  const [query, setQuery] = useState<IListOptions>({
    page: 0,
    limit: 10,
    showPaid: "off",
    showUnPaid: "on",
    showCanceled: "off",
    showFailed: "off"
  });

  useEffect(() => {
    _getAll();
  }, [query]);

  async function _getAll() {
    setLoading(true);
    const result = await getAll(query);
    console.log(result.data);
    setList(result.data.data.transactions);
    if (result.data.data.total.length > 0) {
      setTotal(result.data.data.total[0].count);
    }
    setLoading(false);
  }

  const payInput = (
    <Input
      ref={payRef}
      defaultValue="İşlemleriniz aktarıldı, hesabınıza yansıtılması 4-5 gün sürebilir."
    />
  );
  const canceledInput = <Input ref={canceledRef} />;
  const failInput = <Input ref={failRef} />;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "IBAN",
      dataIndex: "iban_no",
      key: "iban_no",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Exchange Rate",
      dataIndex: "exchange_rate",
      key: "exchange_rate"
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "points"
    },
    {
      title: "Doller",
      dataIndex: "doller",
      key: "doller"
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => {
        return moment(date).format("MMMM Do YYYY, h:mm:ss a");
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        switch (status) {
          case "pending":
            return <Tag color="yellow">{status}</Tag>;
          case "paid":
            return <Tag color="green">{status}</Tag>;
          case "canceled":
            return <Tag color="red">{status}</Tag>;
          case "failed":
            return <Tag color="orange">{status}</Tag>;
        }
      }
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (message: string) => {
        return <Paragraph>{message}</Paragraph>;
      }
    },
    {
      title: "Actions",
      key: "actions",
      render: (all: any) => (
        <span>
          <ButtonGroup size="small">
            <Button
              type="primary"
              onClick={async () => {
                setLoading(true);

                Modal.confirm({
                  centered: true,
                  title: "Add a message",
                  content: payInput,
                  async onOk() {
                    let message = "";
                    if (payRef !== null && payRef.current !== null) {
                      message = payRef.current.state.value;
                    }
                    await mark(all.transaction_id, "paid", message);
                    _getAll();
                  },
                  onCancel() {
                    setLoading(false);
                  }
                });
              }}
            >
              Pay
            </Button>
            <Button
              type="dashed"
              onClick={() => {
                setLoading(true);
                Modal.confirm({
                  centered: true,
                  title: "Add a message",
                  content: failInput,
                  async onOk() {
                    let message = "";
                    if (failRef !== null && failRef.current !== null) {
                      message = failRef.current.state.value;
                    }
                    await mark(all.transaction_id, "failed", message);
                    _getAll();
                  },
                  onCancel() {
                    setLoading(false);
                  }
                });
              }}
            >
              Failed
            </Button>
            <Button
              type="danger"
              onClick={() => {
                setLoading(true);
                Modal.confirm({
                  centered: true,
                  title: "Add a message",
                  content: canceledInput,
                  async onOk() {
                    let message = "";
                    if (canceledRef !== null && canceledRef.current !== null) {
                      message = canceledRef.current.state.value;
                    }
                    await mark(all.transaction_id, "canceled", message);
                    _getAll();
                  },
                  onCancel() {
                    setLoading(false);
                  }
                });
              }}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </span>
      )
    }
  ];

  return (
    <MainLayout>
      <PageHeader
        style={{
          padding: 0,
          paddingBottom: 20
        }}
        title="Payment Control"
        subTitle="List of all the payment request"
      />

      <Form layout="vertical">
        <Form.Item label="Filters">
          <Checkbox
            checked={query.showPaid === "on"}
            onChange={e => {
              const val = e.target.checked ? "on" : "off";

              setQuery(() => {
                return {
                  ...query,
                  showPaid: val
                };
              });
            }}
          >
            showPaid
          </Checkbox>
          <Checkbox
            checked={query.showUnPaid === "on"}
            onChange={e => {
              const val = e.target.checked ? "on" : "off";

              setQuery(() => {
                return {
                  ...query,
                  showUnPaid: val
                };
              });
            }}
          >
            showUnPaid
          </Checkbox>
          <Checkbox
            checked={query.showCanceled === "on"}
            onChange={e => {
              const val = e.target.checked ? "on" : "off";

              setQuery(() => {
                return {
                  ...query,
                  showCanceled: val
                };
              });
            }}
          >
            showCanceled
          </Checkbox>

          <Checkbox
            checked={query.showFailed === "on"}
            onChange={e => {
              const val = e.target.checked ? "on" : "off";

              setQuery(() => {
                return {
                  ...query,
                  showFailed: val
                };
              });
            }}
          >
            showFailed
          </Checkbox>
        </Form.Item>
      </Form>
      <Table
        loading={loading}
        size="small"
        pagination={{
          total: total,
          pageSize: query.limit,
          current: query.page + 1
        }}
        dataSource={list}
        columns={columns}
        rowKey="transaction_id"
        onChange={data => {
          const { current }: any = data;
          setQuery(() => {
            return {
              ...query,
              page: current - 1
            };
          });
        }}
      ></Table>
    </MainLayout>
  );
};
