import axios from "./axios";
import urls from "../config/url";
import { AuthService } from "../config/auth";
import { IQuizAdd } from "../components/AddQuizForm";
import { getQuestions } from "./questions";

export async function addQuiz(data: IQuizAdd, imgType: string) {
  const form = new FormData();
  form.append("starttime", data.starttime);
  form.append("roomsize", data.roomsize.toString());
  form.append("winningprice", data.winningprice.toString());
  form.append("entryfee", data.entryfee.toString());
  form.append("reward_type", data.reward_type);
  form.append("category", data.category);

  data.questionlist.forEach(q => {
    form.append("questionlist[]", q);
  });

  console.log({
    imgType,
    data
  });
  if (imgType === "old") {
    form.append("file_url", data.banner);
  } else {
    form.append("file", data.banner);
    form.append("file_url", "");
  }

  let res: any;
  try {
    const res = await axios({
      method: "post",
      //data: JSON.stringify(data),
      data: form,
      url: `${urls.API_URL}/quiz/add`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    });

    return {
      status: true,
      msg: "Quiz added",
      res
    };
  } catch (error) {
    return {
      status: false,
      msg: "Failed to add Quiz",
      res
    };
  }
}

export async function getQuiz(limit: any, page: any, onlyNew: boolean) {
  return await axios({
    method: "get",
    url: `${urls.API_URL}/quiz/all`,
    params: {
      page,
      limit,
      onlyNew
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });
}

export async function deleteQuiz(id: string) {
  return await axios({
    method: "delete",
    url: `${urls.API_URL}/quiz/delete`,
    params: {
      id
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });
}

export async function getBanners() {
  return await axios({
    method: "get",
    url: `${urls.API_URL}/quiz/banners`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });
}

export async function cancelQuiz(id: string) {
  return await axios({
    method: "post",
    url: `${urls.API_URL}/quiz/cancel`,
    params: {
      quizId: id
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });
}

export async function getGameHistory(page: number, limit: number) {
  return await axios({
    method: "get",
    url: `${urls.API_URL}/quiz/game-history`,
    params: {
      page,
      limit
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });
}

export async function getSingleGameHistory(quizid: string) {
  return await axios({
    method: "get",
    url: `${urls.API_URL}/quiz/single-game-history`,
    params: {
      quizid
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });
}

export async function getPlayerHistory(
  userid: string,
  page: number,
  limit: number
) {
  return await axios({
    method: "get",
    url: `${urls.API_URL}/quiz/player-history`,
    params: {
      page,
      limit,
      userid
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });
}

export async function removeBanner(bannerid: string) {
  return await axios({
    method: "post",
    url: `${urls.API_URL}/quiz/remove-banner`,
    params: {
      bannerid
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });
}

export async function getAutoQuestions() {
  // TODO: API for auto selecting questions
  return getQuestions(1, 10, "", null, 1);
}
