import React from "react";
import { Layout, Menu, Icon, Divider, Card, Avatar, Typography } from "antd";
import { Link, useRouteMatch } from "react-router-dom";
import { AuthService } from "../config/auth";

const links = [
  "/home",
  "/questions",
  "/quiz",
  "/tags",
  "/categories",
  "/users",
  "/version",
  "/pay",
  "/packages",
  "/purchase",
  "/game-history",
  "/settings"
];
export const Sidebar = () => {
  const path = useRouteMatch();
  const selected: string[] = [];

  links.some((curr, i) => {
    if (curr === path.url) {
      const newI = i + 1;
      selected.push(newI.toString());
      return true;
    }
    return false;
  });

  return (
    <Layout.Sider breakpoint="lg" collapsedWidth="0">
      <div
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Typography.Title
          level={3}
          style={{
            color: "white",
            margin: 0
          }}
        >
          KnoWin
        </Typography.Title>
      </div>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={selected}>
        <Menu.Item key="1">
          <Link to="/home">
            <Icon type="home" />
            <span className="nav-text">Home</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="2">
          <Link to="/questions">
            <Icon type="file-add" />
            <span className="nav-text">Question</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to="/quiz">
            <Icon type="unordered-list" />
            <span className="nav-text">Quiz</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="4">
          <Link to="/tags">
            <Icon type="tags" />
            <span className="nav-text">Tags</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="5">
          <Link to="/categories">
            <Icon type="tag" />
            <span className="nav-text">Categories</span>
          </Link>
        </Menu.Item>

        {AuthService.getRole() === "admin" && (
          <Menu.Item key="6">
            <Link to="/users">
              <Icon type="user" />
              <span className="nav-text">Users</span>
            </Link>
          </Menu.Item>
        )}

        {AuthService.getRole() === "admin" && (
          <Menu.Item key="7">
            <Link to="/version">
              <Icon type="sliders" />
              <span className="nav-text">Version</span>
            </Link>
          </Menu.Item>
        )}

        {AuthService.getRole() === "admin" && (
          <Menu.Item key="8">
            <Link to="/pay">
              <Icon type="dollar" />
              <span className="nav-text">Payments</span>
            </Link>
          </Menu.Item>
        )}

        {AuthService.getRole() === "admin" && (
          <Menu.Item key="9">
            <Link to="/packages">
              <Icon type="table" />
              <span className="nav-text">Packages</span>
            </Link>
          </Menu.Item>
        )}

        {AuthService.getRole() === "admin" && (
          <Menu.Item key="10">
            <Link to="/purchase">
              <Icon type="table" />
              <span className="nav-text">Purchase</span>
            </Link>
          </Menu.Item>
        )}

        <Menu.Item key="11">
          <Link to="/game-history">
            <Icon type="setting" />
            <span className="nav-text">Game History</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="12">
          <Link to="/settings">
            <Icon type="setting" />
            <span className="nav-text">Setting</span>
          </Link>
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
};
