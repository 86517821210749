import React, { useState } from "react";
import { getInfoFromIds } from "../api/user";
import { Modal, List, Avatar, Pagination } from "antd";

interface IUserView {
  userList: string[];
  close: () => any;
}
export const UserView: React.FC<IUserView> = ({ userList, close }) => {
  const pageSize = 10;
  const [loading, setLoading] = useState(true);
  const [currList, setCurrList] = useState([]);
  const [page, setPage] = useState(0);

  React.useEffect(() => {
    fetchInfo();
  }, [page]);

  async function fetchInfo() {
    setLoading(true);
    const now = userList.slice(page * pageSize, page * pageSize + pageSize);
    const info = await getInfoFromIds(now);
    console.log(info);
    setCurrList(info.data.data.usersInfo);
    setLoading(false);
  }
  return (
    <Modal title="User List" visible onCancel={close} closable>
      <List
        loading={loading}
        itemLayout="horizontal"
        dataSource={currList}
        pagination={false}
        renderItem={item => {
          return (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={item.avatar} />}
                title={item.username}
                description={item.name}
              />
            </List.Item>
          );
        }}
      />
      <Pagination
        total={userList.length}
        pageSize={pageSize}
        current={page + 1}
        onChange={pg => {
          setPage(pg - 1);
        }}
      />
    </Modal>
  );
};
