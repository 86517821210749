import React, { useState, useEffect } from "react";
import { MainLayout } from "../../layouts/MainLayout";
import {
  PageHeader,
  Table,
  Avatar,
  Input,
  Button,
  Divider,
  Modal,
  notification,
  Form,
  Radio,
  Tag,
  Typography
} from "antd";
import { getAll, updateRoles, genPass } from "../../api/user";
import ButtonGroup from "antd/lib/button/button-group";
import { RefView } from "../../components/RefView";
import {
  PlayCircleOutlined,
  KeyOutlined,
  EditOutlined,
  MessageOutlined
} from "@ant-design/icons";
import { QuizHistory } from "../../components/QuizHistory";
import { SendMessage } from "../../components/SendMessage";
const { Paragraph } = Typography;

export const UsersPage = () => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
    page: 0,
    limit: 10,
    search: "",
    total: 0
  });
  const [list, setList] = useState([]);
  const [open, setOpen] = useState(false);
  const [refId, setRefId] = useState("");
  const [refOpen, setRefOpen] = useState(false);
  const [editId, setEditId] = useState("");
  const [openHis, setOpenHis] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [editInfo, setEditInfo] = useState<any>({});
  const [toUpdate, setToUpdate] = useState<any>({});

  useEffect(() => {
    getAllUser();
  }, [query.page, query.search]);

  async function getAllUser() {
    setLoading(true);
    const res = await getAll(query.page, query.limit, query.search);
    if (res.data.status) {
      console.log(res.data.userList);
      if (res.data.userList) {
        setList(res.data.userList);
        setQuery(() => {
          return {
            ...query,
            total: res.data.total
          };
        });
      }
    }
    setLoading(false);
  }

  const editUser = async () => {
    if (editId === "") {
      notification.error({
        message: "Id not found please refresh the page and try again"
      });
      setEditId("");
      setToUpdate({});
      setOpen(false);
      return;
    }

    setOpen(false);
    setLoading(true);

    await updateRoles({
      userid: editId,
      role: toUpdate.role,
      status: toUpdate.status
    });

    setEditId("");
    setToUpdate({});
    getAllUser();
  };

  const columns = [
    {
      title: "Avatar",
      dataIndex: "avatar",
      key: "avatar",
      render: (url: string) => {
        return <Avatar src={url}>Avatar</Avatar>;
      }
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role: any) => (
        <Tag>
          <b> {role.toUpperCase()} </b>
        </Tag>
      )
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username"
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (item: any) => {
        switch (item) {
          case "active":
            return <Tag color="green">{item}</Tag>;
          case "blocked":
            return <Tag color="red">{item}</Tag>;
        }
      }
    },
    {
      title: "Actions",
      key: "actions",
      render: (items: any) => (
        <span>
          <ButtonGroup size="small">
            <Button
              type="ghost"
              onClick={() => {
                setRefId(items.userid);
                setRefOpen(true);
              }}
              icon="eye"
            />
            <Button
              type="ghost"
              onClick={() => {
                setRefId(items.userid);
                setOpenHis(true);
              }}
            >
              <PlayCircleOutlined />
            </Button>
            <Button
              type="ghost"
              onClick={() => {
                setRefId(items.userid);
                setOpenMessage(true);
              }}
            >
              <MessageOutlined />
            </Button>
            <Button
              type="ghost"
              onClick={() => {
                setEditId(items.userid);
                setEditInfo(items);
                const { role, status } = items;
                setToUpdate({
                  role,
                  status
                });
                setOpen(true);
              }}
            >
              <EditOutlined />
            </Button>
            {!items.password_set && (
              <Button
                type="ghost"
                onClick={async () => {
                  const res: any = await genPass(items.userid);

                  if (res.data.status) {
                    Modal.info({
                      content: (
                        <Paragraph copyable>{res.data.data.pass}</Paragraph>
                      )
                    });
                  } else {
                    notification.error({
                      message: res.data.status
                    });
                  }
                }}
              >
                <KeyOutlined />
              </Button>
            )}
          </ButtonGroup>
        </span>
      )
    }
  ];

  return (
    <MainLayout>
      <PageHeader title="User Controle" />
      <Input.Search
        placeholder="Search Users"
        enterButton="Search"
        allowClear
        onSearch={value => {
          setQuery(() => {
            return {
              ...query,
              search: value
            };
          });
        }}
      />
      <Divider />
      <Table
        loading={loading}
        size="small"
        pagination={{
          total: query.total,
          pageSize: query.limit,
          current: query.page + 1
        }}
        dataSource={list}
        columns={columns}
        rowKey="userid"
        onChange={data => {
          const { current }: any = data;
          setQuery(() => {
            return {
              ...query,
              page: current - 1
            };
          });
        }}
      ></Table>

      <Modal
        title="Update User"
        visible={open}
        onOk={() => editUser()}
        onCancel={() => {
          setEditId("");
          setOpen(false);
        }}
      >
        <Form>
          <Form.Item label="Role">
            <Radio.Group
              defaultValue={editInfo.role}
              onChange={t => {
                setToUpdate((s: any) => {
                  return {
                    ...s,
                    role: t.target.value
                  };
                });
              }}
            >
              <Radio.Button value="admin">Admin</Radio.Button>
              <Radio.Button value="moderator">Moderator</Radio.Button>
              <Radio.Button value="user">User</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="Status">
            <Radio.Group
              defaultValue={editInfo.status}
              onChange={t => {
                setToUpdate((s: any) => {
                  return {
                    ...s,
                    status: t.target.value
                  };
                });
              }}
            >
              <Radio.Button value="active">Active</Radio.Button>
              <Radio.Button value="blocked">Block</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>

      {refOpen && <RefView close={() => setRefOpen(false)} userid={refId} />}
      {openHis && (
        <QuizHistory close={() => setOpenHis(false)} userid={refId} />
      )}
      {openMessage && (
        <SendMessage userid={refId} close={() => setOpenMessage(false)} />
      )}
    </MainLayout>
  );
};
