import React, { useState } from "react";
import { sendMessage } from "../api/user";
import { Modal, Form, Input, notification } from "antd";

interface IUserView {
  userid: string;
  close: () => any;
}
export const SendMessage: React.FC<IUserView> = ({ userid, close }) => {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  async function _sendMessage() {
    setLoading(true);
    if (title === "" || message === "") {
      notification.error({
        message: `Title and message can't be empty`
      });
      setLoading(false);
      return;
    }

    try {
      await sendMessage(userid, title, message);
      notification.success({
        message: `Message sent!`
      });
    } catch (error) {
      notification.error({
        message: `Error on sending message Please try again!`
      });
    }

    setLoading(false);
  }

  return (
    <Modal
      title="Send Message"
      visible
      onCancel={close}
      closable
      onOk={_sendMessage}
      confirmLoading={loading}
    >
      <Form>
        <Form.Item label="Title" required>
          <Input value={title} onChange={e => setTitle(e.target.value)} />
        </Form.Item>
        <Form.Item label="Message" required>
          <Input.TextArea
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
