import axios from "./axios";
import urls from "../config/url";
import { AuthService } from "../config/auth";

export async function getTags() {
  const res = await axios({
    method: "get",
    url: `${urls.API_URL}/ui/tags`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });

  return res.data;
}

export async function setTags(tag: string) {
  const res = await axios({
    method: "post",
    params: {
      tag
    },
    url: `${urls.API_URL}/ui/tags`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });

  return res.data;
}

export async function getCategories() {
  const res = await axios({
    method: "get",
    url: `${urls.API_URL}/ui/category`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });

  return res.data;
}

export async function setCategories(category: string) {
  const res = await axios({
    method: "post",
    params: {
      category
    },
    url: `${urls.API_URL}/ui/category`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });

  return res.data;
}

export async function update(old: string, name: string, type: string) {
  const res = await axios({
    method: "post",
    url: `${urls.API_URL}/ui/all?name=${name}&type=${type}&old=${old}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });

  return res.data;
}

export async function deleteUi(name: string, type: string) {
  const res = await axios({
    method: "delete",
    url: `${urls.API_URL}/ui?name=${name}&type=${type}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });

  return res.data;
}

export async function getVersion() {
  const res = await axios({
    method: "get",
    url: `${urls.API_URL}/version`,
    headers: {
      "Content-Type": "application/json"
    }
  });

  return res.data;
}

export async function setVersion(isUnderMaintenance: boolean, version: string) {
  const res = await axios({
    method: "post",
    url: `${urls.API_URL}/version?isUnderMaintenance=${isUnderMaintenance}&version=${version}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });

  return res.data;
}
