import React, { useState, useEffect } from "react";
import p from "immer";
import { Button, Select, List, Typography, Checkbox } from "antd";
import { getQuestions } from "../api/questions";
import { IQuestionData } from "../pages/questions";
import { Tags } from "./Tags";

export const AutoQuestionSelector: React.FC<{
  updateList: (ids: any) => any;
}> = ({ updateList }) => {
  const [list, setList] = useState<IQuestionData[]>([]);
  const [enableFilters, setEnableFilters] = useState(false);
  const [filters, setFilters] = useState({
    level: "practice",
    tags: []
  });

  useEffect(() => {
    handleSearch("");
  }, [filters.level, filters.tags, enableFilters]);

  const OptTags = Tags();
  const handleSearch = async (e: string) => {
    let result: any;

    if (enableFilters) {
      console.log(filters);
      result = await getQuestions(1, 10, e, filters, 1);
    } else {
      result = await getQuestions(1, 10, e, null, 1);
    }

    if (result.status) {
      const _data: IQuestionData[] = result.data.questions;
      setList(_data);
      const ids = _data.map(item => item.questionid);
      updateList(ids);
      console.log(ids);
    }
  };

  return (
    <div>
      <div>
        <Checkbox
          checked={enableFilters}
          onChange={e => setEnableFilters(e.target.checked)}
        >
          Enable Filters
        </Checkbox>
        {enableFilters && (
          <div>
            <span>Level:</span>
            <Select
              value={filters.level}
              onChange={(e: any) => {
                const nextState = p(filters, draft => {
                  draft.level = e;
                });
                setFilters(nextState);
              }}
              placeholder="Level"
            >
              <Select.Option value="all">All</Select.Option>
              <Select.Option value="practice">Pratice</Select.Option>
              <Select.Option value="easy">Easy</Select.Option>
              <Select.Option value="medium">Medium</Select.Option>
              <Select.Option value="hard">Hard</Select.Option>
            </Select>

            <span>Tags:</span>
            <Select
              defaultValue={filters.tags}
              mode="tags"
              onChange={(e: any) => {
                const nextState = p(filters, draft => {
                  draft.tags = e;
                });
                setFilters(nextState);
              }}
            >
              {OptTags}
            </Select>
          </div>
        )}
      </div>
      <br />
      <List
        header={<div>Questions selected</div>}
        bordered
        dataSource={list}
        renderItem={item => (
          <List.Item>
            {item.title} Tags:{" "}
            <Typography.Text mark> {item.tags.join(",")} </Typography.Text>
            <Button
              size="small"
              shape="circle"
              icon="close"
              onClick={() => {
                const newList = list.filter(
                  i => i.questionid !== item.questionid
                );
                setList(newList);

                const ids = newList.map(item => item.questionid);
                updateList(ids);
                console.log(ids);
              }}
            />
          </List.Item>
        )}
      />
    </div>
  );
};
