import React from "react";
import { PageHeader, Table, notification, Icon } from "antd";
import moment from "moment";
import { MainLayout } from "../../layouts/MainLayout";
import { AddNewPackageForm, IPackage } from "../../components/AddNewPackage";
import ButtonGroup from "antd/lib/button/button-group";
import { Button } from "antd";
import { getPackage, deletePackage } from "../../api/packages";

const initalValues = {
  packageId: "",
  title: "",
  heartCount: 0,
  passCount: 0,
  fiftyFiftyCount: 0,
  twoAnswerCount: 0,
  keyCount: 0,
  purchaseMode: "money",
  price: 0,
  priceSold: 0,
  type: "package",
  isOffer: false,
  discountPercent: 0,
  validTill: ""
};

export const PackagesPage = () => {
  const [formOpen, setFormOpen] = React.useState(false);
  const [formInitalValues, setFormInitalvalues] = React.useState(initalValues);
  const [loading, setLoading] = React.useState(true);
  const [table, setTable] = React.useState([]);
  const [pag, setPag] = React.useState({
    page: 0,
    total: 0,
    count: 10
  });

  React.useEffect(() => {
    if (!formOpen) {
      fetchAndSetTable();
    }
  }, [pag.page, formOpen]);

  async function fetchAndSetTable() {
    setLoading(true);
    const { page, count, total } = pag;
    const result = await getPackage(count, page);
    setTable(result.data.data.packages);
    console.log(result.data.data.packages);
    setPag({
      ...pag,
      total: result.data.data.total
    });
    setLoading(false);
  }
  const columns = [
    {
      title: "Package Id",
      dataIndex: "packageId",
      key: "packageId"
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title"
    },
    {
      title: "Heart",
      dataIndex: "heartCount",
      key: "heartCount"
    },
    {
      title: "Pass",
      dataIndex: "passCount",
      key: "passCount"
    },
    {
      title: "50-50",
      dataIndex: "fiftyFiftyCount",
      key: "fiftyFiftyCount"
    },
    {
      title: "2-Ans",
      dataIndex: "twoAnswerCount",
      key: "twoAnswerCount"
    },
    {
      title: "Keys",
      dataIndex: "keyCount",
      key: "keyCount"
    },
    {
      title: "Purchase Mode",
      dataIndex: "purchaseMode",
      key: "purchaseMode"
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price"
    },
    {
      title: "Retail Price",
      dataIndex: "priceSold",
      key: "priceSold"
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type"
    },
    {
      title: "Is Offer",
      dataIndex: "isOffer",
      key: "isOffer",
      render: (isOffer: boolean) => {
        if (isOffer) {
          return "Yes";
        } else {
          return "No";
        }
      }
    },
    {
      title: "Discount %",
      dataIndex: "discountPercent",
      key: "discountPercent"
    },
    {
      title: "Expires In",
      dataIndex: "validTill",
      key: "validTill",
      render: (date: string) => {
        if (date) {
          return <span>{moment(date).fromNow()}</span>;
        } else {
          return "Never";
        }
      }
    },
    {
      title: "Actions",
      key: "actions",
      render: (item: any) => (
        <span>
          <ButtonGroup size="small">
            <Button
              type="primary"
              onClick={() => {
                console.log(item);
                setFormInitalvalues(item);
                setFormOpen(true);
              }}
            >
              <Icon type="edit" />
            </Button>
            <Button
              onClick={async () => {
                await deletePackage(item.packageId);
                notification.info({
                  message: `Package with id ${item.packageId} deleted`
                });
                fetchAndSetTable();
              }}
              type="danger"
            >
              <Icon type="delete" />
            </Button>
          </ButtonGroup>
        </span>
      )
    }
  ];

  return (
    <MainLayout>
      <PageHeader
        style={{
          padding: 0,
          paddingBottom: 20
        }}
        title="Packages"
        subTitle="Add, remove or update in-app packages"
      />
      <ButtonGroup
        style={{
          margin: "20px 0"
        }}
      >
        <Button
          onClick={() => {
            setFormInitalvalues(initalValues);
            setFormOpen(true);
          }}
          type="primary"
          icon="plus"
        >
          Add Package
        </Button>
      </ButtonGroup>

      {formOpen && (
        <AddNewPackageForm
          close={() => setFormOpen(false)}
          initValue={formInitalValues}
        />
      )}

      <Table
        pagination={{
          total: pag.total,
          pageSize: pag.count,
          current: pag.page + 1
        }}
        loading={loading}
        dataSource={table}
        columns={columns}
        rowKey="packageId"
        onChange={data => {
          const { current }: any = data;
          setPag({
            ...pag,
            page: current - 1
          });
        }}
        size="small"
      ></Table>
    </MainLayout>
  );
};
