import { getInfo } from "../api/user";

let token: any = null;
let role: any = null;

export class AuthService {
  static isAuth() {
    return !!localStorage.getItem("token") || false;
  }

  static logout() {
    localStorage.clear();
    token = null;
    role = null;
  }

  static getToken() {
    return localStorage.getItem("token");
  }

  static getRole() {
    return localStorage.getItem("role");
  }

  static async setToken(t: string) {
    //token = t;

    localStorage.setItem("token", t);
    const res = await getInfo();
    const { username, name, avatar } = res.data.data.info;

    localStorage.setItem("username", username);
    localStorage.setItem("name", name);
    localStorage.setItem("avatar", avatar);
  }

  static setRole(t: string) {
    localStorage.setItem("role", t);
  }
}
