import React from "react";
import { Router, Route, Switch, Redirect, RouteProps } from "react-router-dom";
import history from "./config/histroy";

import "./App.css";
import { Auth } from "./pages/auth";
import { AuthService } from "./config/auth";
import { Questions } from "./pages/questions";
import { Quiz } from "./pages/quiz";
import { Home } from "./pages/home";
import { AddTags } from "./pages/tags";
import { AddCategories } from "./pages/categories";
import { UsersPage } from "./pages/Users";
import { PayPage } from "./pages/pay";
import { Settings } from "./pages/settings";
import { PackagesPage } from "./pages/packages";
import { Version } from "./pages/version";
import { PurchasePage } from "./pages/purchase";
import {GamePage} from "./pages/games";

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        AuthService.isAuth() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

class App extends React.Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={Auth} />

          <PrivateRoute path="/home">
            <Home />
          </PrivateRoute>
          <PrivateRoute path="/questions">
            <Questions />
          </PrivateRoute>
          <PrivateRoute path="/quiz">
            <Quiz />
          </PrivateRoute>
          <PrivateRoute path="/tags">
            <AddTags />
          </PrivateRoute>
          <PrivateRoute path="/categories">
            <AddCategories />
          </PrivateRoute>
          <PrivateRoute path="/users">
            <UsersPage />
          </PrivateRoute>
          <PrivateRoute path="/version">
            <Version />
          </PrivateRoute>
          <PrivateRoute path="/pay">
            <PayPage />
          </PrivateRoute>
          <PrivateRoute path="/packages">
            <PackagesPage />
          </PrivateRoute>
          <PrivateRoute path="/settings">
            <Settings />
          </PrivateRoute>
          <PrivateRoute path="/purchase">
            <PurchasePage />
          </PrivateRoute>
          <PrivateRoute path="/game-history">
            <GamePage/>
          </PrivateRoute>
        </Switch>
      </Router>
    );
  }
}
export default App;
