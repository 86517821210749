import React, { useEffect, useState } from "react";
import { MainLayout } from "../../layouts/MainLayout";
import { PageHeader, Table, Avatar, Select, Checkbox, Form, Input } from "antd";
import { getPurchaseInfo } from "../../api/packages";
import moment from "moment";

const { Option } = Select;
const limit = 10;
export const PurchasePage = () => {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [types, setTypes] = useState(["coin", "money"]);
  const [data, setData] = useState([]);
  const [onlyRefund, setOnlyRefund] = useState(false);

  useEffect(() => {
    fetchAll();
  }, [page, types, onlyRefund, search]);

  async function fetchAll() {
    setLoading(true);
    const res = await getPurchaseInfo(page, limit, types, onlyRefund, search);
    const { total, purchase_list } = res.data;
    console.log(res.data);
    setTotal(total);
    setData(purchase_list);
    setLoading(false);
  }

  const columns = [
    {
      title: "Avatar",
      dataIndex: "userinfo",
      key: "avatar",
      render: (userinfo: any) => {
        return <Avatar src={userinfo.avatar} />;
      }
    },
    {
      title: "Purchase Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => {
        return <span>{moment(date).fromNow()}</span>;
      }
    },
    {
      title: "Username",
      dataIndex: "userinfo",
      key: "username",
      render: (userinfo: any) => {
        return userinfo.username;
      }
    },
    {
      title: "Name",
      dataIndex: "userinfo",
      key: "name",
      render: (userinfo: any) => {
        return userinfo.name;
      }
    },
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId"
    },
    {
      title: "Mode",
      dataIndex: "coinPurchase",
      key: "coinPurchase",
      render: (isOk: boolean) => (isOk ? "Coin" : "Money")
    },
    {
      title: "Refund Made",
      dataIndex: "refundMade",
      key: "refundMade",
      render: (isOk: boolean) => (isOk ? "Yes" : "No")
    },
    {
      title: "Product ID",
      dataIndex: "productId",
      key: "productId"
    }
  ];

  return (
    <MainLayout>
      <PageHeader
        style={{
          padding: 0,
          paddingBottom: 20
        }}
        title="Purchase Packages"
        subTitle="List of all the packages purchased"
      />
      <Form layout="inline">
        <Form.Item>
          <Input.Search
            placeholder="Search"
            enterButton="Search"
            allowClear
            onSearch={value => {
              setSearch(value);
            }}
          />
        </Form.Item>
        <Form.Item label="Show Refunded">
          <Checkbox
            checked={onlyRefund}
            onChange={(e: any) => {
              setOnlyRefund(e.target.checked);
            }}
          />
        </Form.Item>
        <Form.Item label="Select Mode">
          <Select
            defaultValue="both"
            style={{ width: 120 }}
            onChange={(value: string) => {
              if (value === "both") {
                setTypes(["coin", "money"]);
              } else {
                setTypes([value]);
              }
            }}
          >
            <Option value="coin">Coin</Option>
            <Option value="money">Money</Option>
            <Option value="both">Coin/Money</Option>
          </Select>
        </Form.Item>
      </Form>

      <br />
      <br />
      <Table
        pagination={{
          total: total,
          pageSize: limit,
          current: page + 1
        }}
        loading={loading}
        dataSource={data}
        columns={columns}
        rowKey="_id"
        onChange={data => {
          const { current }: any = data;
          setPage(current - 1);
        }}
        size="small"
      ></Table>
    </MainLayout>
  );
};
