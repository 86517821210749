import axios from "./axios";
import { IQuestionData } from "../pages/questions";
import urls from "../config/url";
import { AuthService } from "../config/auth";

export async function addQuestion(formData: IQuestionData) {
  const { percentage, ...all } = formData;

  const res = await axios({
    method: "post",
    data: JSON.stringify(all),
    url: `${urls.API_URL}/question/add`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });

  console.log(res.data);
  if (res.data.status) {
    return {
      status: true,
      msg: "Questions added"
    };
  } else {
    return {
      status: false,
      msg: "Failed to add question"
    };
  }
}

export async function getQuestions(
  page: number,
  count: number,
  search?: string,
  filters?: any,
  onlyNew?: any,
  rangeEnable?: boolean,
  range?: number
) {
  console.log(onlyNew);
  const params: any = {
    page,
    count,
    onlyNew
  };

  if (search) {
    params["search"] = search;
  }

  if (filters) {
    if (filters.level && filters.level !== "all") {
      params["level"] = filters.level;
    }
    if (filters.tags) {
      params["tags"] = filters.tags;
    }
  }

  if (rangeEnable) {
    params["rangeEnable"] = rangeEnable;
    params["range"] = range;
  } else {
    params["rangeEnable"] = false;
    params["range"] = 100;
  }

  const data = await axios.get(`${urls.API_URL}/question/all`, {
    params,
    data: filters,
    headers: {
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });
  return data.data;
}

export async function removeQuestion(id: string) {
  const data = await axios.delete(`${urls.API_URL}/question/delete`, {
    params: {
      id
    },
    headers: {
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });

  console.log(data);
  return data.data;
}

export async function resetQuestion(id: string) {
  console.log("resetQuestion");
  const data = await axios.put(
    `${urls.API_URL}/question/reset`,
    {},
    {
      params: {
        id
      },
      headers: {
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    }
  );

  console.log(data);
  return data.data;
}

export async function addQuestionBulk(table: IQuestionData[]) {
  const res = await axios({
    method: "post",
    data: JSON.stringify(table),
    url: `${urls.API_URL}/question/add-bulk`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });

  console.log(res.data);
  if (res.data.status) {
    return {
      status: true,
      msg: "Questions added"
    };
  } else {
    return {
      status: false,
      msg: "Failed to add question"
    };
  }
}

export async function updateQuestion(data: IQuestionData) {
  console.log(data);
  const id = data.questionid;
  const toSend: IQuestionData = {
    title: data.title,
    options: data.options,
    answerKey: data.answerKey,
    level: data.level,
    tags: data.tags
  };
  const res = await axios.put(`${urls.API_URL}/question/update`, toSend, {
    params: {
      id
    },
    headers: {
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  });

  return res.data;
}
