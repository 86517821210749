import React, { useState } from "react";
import {
  PageHeader,
  Form,
  Card,
  Input,
  Tabs,
  Button,
  notification
} from "antd";
import { MainLayout } from "../../layouts/MainLayout";
import { setPass } from "../../api/user";
import "./settings.css";

const { TabPane } = Tabs;

export const Settings = () => {
  const [old, setOld] = useState("");
  const [_new, _setNew] = useState("");
  const [_confirm, _setConfirm] = useState("");

  return (
    <MainLayout>
      <PageHeader
        style={{
          paddingLeft: 0
        }}
        title="Settings"
        subTitle="Control your settings"
      />
      <div className="card-container">
        <Tabs type="card">
          <TabPane tab="Change Password" key="1">
            <Form
              layout="vertical"
              style={{
                maxWidth: 300
              }}
              onSubmit={async e => {
                e.preventDefault();
                if (_new === _confirm) {
                  const status = await setPass(old, _confirm);
                  if (status) {
                    if (status.data.status) {
                      notification.success({
                        message: status.data.message
                      });
                      setOld("");
                      _setNew("");
                      _setConfirm("");
                    } else {
                      notification.error({
                        message: status.data.message
                      });
                    }
                  }
                } else {
                  notification.error({
                    message:
                      "Confirm password is diffrent then the new Password"
                  });
                }
              }}
            >
              <Form.Item label="Old Password" required>
                <Input.Password
                  placeholder="Old Password"
                  type="password"
                  value={old}
                  onChange={e => {
                    setOld(e.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item label="New Password" required>
                <Input.Password
                  placeholder="New Password"
                  type="password"
                  value={_new}
                  onChange={e => {
                    _setNew(e.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item label="Confirm New Password" required>
                <Input.Password
                  placeholder="Confirm New Password"
                  type="password"
                  value={_confirm}
                  onChange={e => {
                    _setConfirm(e.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Confim
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Others" key="2">
            <div>Hello World</div>
          </TabPane>
        </Tabs>
      </div>
    </MainLayout>
  );
};
